import { useState } from "react";
import { Link } from "react-router-dom";

const Campaigns = () => {
	const [open, setOpen] = useState(false);
    return(
        <>
			<main class="main-middle-content-section  header-custom-main">
				<div class="container-fluid container-xl mt-5 pt-2  pb-5">
					<div class="leaderboad-list-main-contianer">
						<div class="leaderboard-header-sec">
							<div class="lt-pannel-head pt-2 pb-3">
								<h3>Campaign Test</h3>
							</div>
							<p><button class="campaign-page-btn mt-2">Create New Campaign</button></p>
						</div>

						<div class="leader-list-main">
							<table class="table custom-table-main border-top-0 ">
								<thead>
									<tr>
										<th scope="col">Campaign Name</th>
										<th scope="col">User Name</th>
										<th scope="col">Signup Date</th>
										<th scope="col">Referred By</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td class="w-40" scope="row">
											<Link
												to={"/campaign/view/650615e4e1f23e158ce5ee37"}
												onClick={() => {
													setOpen(false);
												}}>
												Campaign 01
											</Link>
										</td>
										<td class="w-20">
											<div class="cus-flex pt-2">
												<figure>
													<img src="Images/user-small.svg" />
												</figure>
												<span>John Doe</span>
											</div>
										</td>
										<td class="w-20">02/02/2023</td>
										<td class="w-20"><a href="">Ibrahim</a></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</main>
        </>
    )
};

export default Campaigns;
