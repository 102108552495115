/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import "bootstrap/dist/css/bootstrap.min.css"
import "./App.css";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "./components/layout";
import Login from "./components/login";
import LoaderComponent from "./components/loader";
import {
    SetloaderData
} from "./store/reducer";
import Campaigns from "./components/campaign";
import ViewCampaign from "./components/campaign/view";
import RequireAuth from "./hooks/useAuth";


function App() {
    const dispatch = useDispatch();
    const userToken = localStorage.getItem("token");

    useEffect(() => {
        return () => {
            dispatch(SetloaderData(false));
        };
    }, []);
    return (
        <>
            <LoaderComponent />
            <div className="App">
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route element={<RequireAuth authorized={userToken ? true : false} />}>
                        <Route path="/" element={<Layout />}>
                            <Route index element={<Campaigns />} />
                            <Route element={<Campaigns />} />
                            <Route path="/campaign/view/:_id" element={<ViewCampaign />} />
                        </Route>
                    </Route>
                </Routes>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={100000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
            />
        </>
    );
}

export default App;
