export const validationMessages = {
    require: "This field is required",
    unableToLogin: "Unable to login. Please try after some time.",
    name: `Name is required`,
    nameMax: `Name is too long ( Maximum 100 characters )`,
    descriptionMax: "Description is too long ( Maximum 1000 characters )",
    descriptionLow: "Description is required",
    allowAlphabets: `This field allow alphabets only.`,
    passwReq: `Password is required`,
    validPass: `Password must included atleast`,
    cpasswReq: `Confirm Password is required`,
    currentasswReq: `Current Password is required`,
    notMatchPassw: `Password and Confirm Password should be same`,
  };
  const apiURl = {
    getLink: "/v1/links",
    resetMail: "/v1/user/mail/reset",
    resetPassword: "/v1/user/password/reset",
    getCampaign: "/v1/referral/campaign/get",
  };
  
  export default apiURl;
  