import { Navigate, Outlet, useLocation } from "react-router-dom";

const RequireAuth = ({ authorized }) => {
    const location = useLocation();
    const userToken = localStorage.getItem("token");

    return (
        <>
            {userToken ? (
                !authorized ? (
                    <Navigate to={"/unauthorized"} state={{ from: location }} replace />
                ) : (
                    <Outlet />
                )
            ) : (
                <Navigate to={"/login"} state={{ from: location }} replace />
            )}
        </>
    );
};

export default RequireAuth;
