import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { API } from "../../apiwrapper";
import apiURl from "../../store/actions/api-url";
import { BASECONFIG } from "../../config";

const ViewCampaign = () => {
	const [campaignData, setCampaignData] = useState();
	const { id } = useParams();
	const fetchCampaign = async () => {
		try {
			let url = apiURl.getCampaign;
			await API({
				base_url: BASECONFIG.BASE_URL_USER,
				url: `${url}/${id}`,
			}).then((data) => {
				console.log(data);
				if (data?.status || data?.status === "true") {
					setCampaignData(data);
				} else {
					toast.warn(data?.message);
				}
			});
		} catch (error) {
			toast.error(error);
			throw Error(error);
		}
	};
	useEffect(() => {
		fetchCampaign();
	}, []);

	return (
		<>
			<main class="main-middle-content-section  header-custom-main">
				<div class="container-fluid container-xl mt-5 pt-2  pb-5">
					<div class="leaderboad-list-main-contianer">
						<div class="leaderboard-header-sec">
							<div class="lt-pannel-head pt-2 pb-3">

								<h3>Campaign 01</h3>
							</div>
							<p><button class="campaign-page-btn mt-2">End Campaign</button></p>
						</div>
						<div class="cmapaign-header-table-main">
							<div class="row">
								<div class="col-md-3 campaign-user-top-sec">
									<figure>
										<i class="fas fa-calendar-alt"></i>
									</figure>
									<div>
										<p>Campaign Started</p>
										<h3>02/02/2023</h3>
									</div>
								</div>

								<div class="col-md-3 campaign-user-top-sec">
									<figure class="ml-3">
										<i class="fas fa-calendar-alt"></i>
									</figure>
									<div>
										<p>Campaign Ends</p>
										<h3>02/02/2023</h3>
									</div>
								</div>

								<div class="col-md-3 campaign-user-top-sec">
									<figure class="ml-3">
										<i class="fas fa-users"></i>
									</figure>
									<div>
										<p>Number Of referrals</p>
										<h3>2,245</h3>
									</div>
								</div>

								<div class="col-md-3 campaign-user-top-sec">
									<figure class="ml-3">
										<i class="fas fa-user"></i>
									</figure>
									<div>
										<p>Active Users</p>
										<h3>548</h3>
									</div>
								</div>

							</div>
						</div>
						<div class="leader-list-main">
							<table class="table custom-table-main border-top-0 ">
								<thead>
									<tr>
										<th scope="col">Position</th>
										<th scope="col">User Name</th>
										<th scope="col">Referral Count</th>
										<th scope="col">Successful Count</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td class="w-15" scope="row">01</td>
										<td class="w-40">
											<div class="cus-flex pt-2">
												<figure>
													<img src="../../Image/user-small.svg" />
												</figure>
												<span>John Doe</span>
											</div>
										</td>
										<td class="w-30">77</td>
										<td class="w-15">44</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</main>
		</>
	)
};

export default ViewCampaign;