/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef } from "react";
import { BASECONFIG } from "../../config";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  isValid,
  reqLoginPassWord,
  validateEmail,
} from "../../validation/input-validation";
import { API } from "../../apiwrapper";
import { useDispatch } from "react-redux";
import { SetAuthUserData } from "../../store/reducer/auth-user";
import { useGoogleLogin } from "@react-oauth/google";
import { SetloaderData } from "../../store/reducer";
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const [recover, setRecover] = useState(false);
  const [userId, setUserId] = useState();
  const [edit, setEdit] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [errors, setErrors] = useState({});
  const [apiMessage, setApiMessage] = useState({ message: "", status: "" });
  const recaptcha = useRef();
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const dispatch = useDispatch();
  const [inpField, setInpField] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => setUser(tokenResponse),
  });

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const { email = "", password = "" } = inpField;

  const navigate = useNavigate();

  const handleChange = (e) => {
    setInpField({
      ...inpField,
      [e.target.name]: e.target.value,
    });
    handleValidate(e);
    setApiMessage({ message: "", status: "" });
  };

  const handleValidate = (e) => {
    const errors1 = {};
    switch (e.target.name) {
      case "email":
        errors1.email = validateEmail(e.target.value);
        break;
      case "password":
        errors1.password = reqLoginPassWord(e.target.value);
        break;
      default:
        break;
    }
    setErrors(errors1);
  };

  const validateAll = () => {
    let err1 = {};
    err1.email = validateEmail(email);
    err1.password = reqLoginPassWord(password);
    return err1;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors((prevErrors) => ({
      ...prevErrors,
      recaptcha: "",
    }));

    const recaptchaValue = recaptcha.current.getValue();

    if (!recaptchaValue) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        recaptcha: "Please verify the reCAPTCHA.",
      }));
      return;
    }
    try {
      let captchaResponse = await API({
        base_url: BASECONFIG.BASE_URL_USER,
        url: "/v1/user/captchaVerify",
        method: "POST",
        body: {
          captchaValue: recaptchaValue,
        },
      });

      if (!captchaResponse?.success) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          recaptcha: "Invalid reCAPTCHA. Please try again.",
        }));
        recaptcha.current.reset();
        return;
      }
    } catch (error) {
      console.error("Error verifying reCAPTCHA:", error);
      return;
    }

    try {
      let err = validateAll();
      if (isValid(err)) {
        setLoading(true);
        let payload = {
          ...inpField,
          email: inpField?.email,
          password: inpField?.password,
        };
        let url = "/v1/user/login";
        dispatch(SetloaderData(true));
        await API({
          base_url: BASECONFIG.BASE_URL_USER,
          url: url,
          method: edit ? "PUT" : "POST",
          body: payload,
          formData: false,
        }).then((data) => {
          if (data?.status || data?.status === true) {
            if (data?.data) {
              localStorage.setItem("token", data?.data?.token);
              dispatch(SetAuthUserData(data?.data));
              setUserId(data?.data?._id);
              navigate("/");
            } else {
              setApiMessage({ message: data?.message, status: data?.status });
            }
          } else {
            setApiMessage({ message: data?.message, status: data?.status });
          }
        });
      } else {
        setErrors(err);
      }
    } catch (error) {
      toast(error, { type: "error" });
      setApiMessage({ message: error, status: false });
    } finally {
      dispatch(SetloaderData(false));
      setLoading(false);
    }
    };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    if (value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        recaptcha: "",
      }));
    }
  };

  return (
    <>
     <main class="main-middle-content-section header-custom-main">
					<div class="container-fluid container-xl">	
						<div class="login-input-content-sec row">
							<div class="login-content-sec col-md-6">
								<h4>Log in to Mabrook</h4>
								<p>Enter your details below.</p>
								<div class="input-section-main">
                <input
                    name="email"
                    value={email}
                    type="email"
                    placeholder="Email address"
                    className="form-control"
                    onChange={handleChange}
                  />
								</div>
								<div class="input-section-main">
                <input
                    name="password"
                    value={password}
                    type={passwordType}
                    placeholder="Password"
                    className="form-control"
                    onChange={handleChange}
                  />
								</div>
								{errors.password ? (
                  <>
                    {" "}
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.password}
                    </span>
                  </>
                ) : (
                  ""
                )}
                {apiMessage.message ? (
                  <>
                    {" "}
                    <span
                      className={
                        apiMessage?.status ? "text-danger" : "text-danger"
                      }
                      style={{ fontSize: "14px" }}
                    >
                      {apiMessage.message}
                    </span>
                  </>
                ) : (
                  ""
                )}
								{/* <div class="input-section-main keep-sign-text">
									<label><input type="checkbox"> <span>Keep me signed in</span></label>
								</div> */}
								<p class="forgot-password-sec">
                Forgot your password?{" "}
                  <Link to={"/recoverPassword"}>
                    <span className="blue-text">Recover password</span>
                  </Link>
								</p>
                <ReCAPTCHA
                  ref={recaptcha}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  style={{ display: "inline-block" }}
                  onChange={handleRecaptchaChange}
                />
                <br />
                {errors.recaptcha && (
                  <span className="text-danger" style={{ fontSize: "14px" }}>
                    {errors.recaptcha}
                  </span>
                )}
                <br /> 
                <button className="login-page-btn" onClick={handleSubmit} disabled={loading}>
                    {loading ? "Please Wait..." : "Log In"}
                  </button>
								<div class="section-speretor">
									<hr/> <span> or </span> <hr/>
								</div>
							</div>
							<figure class="col-md-6 rt-img-login-sec">
								<img src="Image/login-image.png"/>
							</figure>
						</div>
		
					</div>
				</main>
    </>
  );
};

export default Login;
